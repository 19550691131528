import WJTBLogo from "../../asset/image/career/logo_thinkbig.png";
import BookswageLogo from "../../asset/image/career/logo_bookswage.png";

export const dataList = [
  {
    image: BookswageLogo,
    title: "(주) 북틀",
    date: "2023.12 ~ 2024.06",
    descriptions: [
      "국내 1위, 전자책 솔루션을 서비스하는 기업입니다.",
      "<b>풀스택 포지션</b>으로 <b>컨텐츠 웹 뷰어와 기업 내 클라우드 서비스의 기능 개발 및 유지보수</b> 업무를 담당했으며, 컨텐츠 웹 뷰어 및 클라우드 서비스 API의 <b>기술 스택 전환</b>을 진행하였습니다.  ",
    ],
    contributionList: [
      {
        name: "도서 컨텐츠 웹 뷰어 React 기술 스택 전환",
        date: "2024.04 ~ 2024.06",
        descriptions: [
          "기존 ES5로 개발된 웹 COMIC 뷰어 <b>React로 기술 스택 전환</b> 진행",
          "이후 하나의 모듈로 구성되어있던 뷰어 기능 모듈 역할별 분리를 통한 추상화",
          "추상화된 각 모듈들을 Webpack을 활용하여 번들링 및 NPM 라이브러리화하여 <b>프로젝트 경량화 및 재사용성 증진</b>",
          "현재 페이지, 목차 리스트와 같이 <b>동적으로 변하는 데이터를 React의 가상 DOM 활용을 위해 useState로 변경</b>",
          "웹 뷰어가 리엑트 라이프 사이클 내 동작하도록 변경하여 <b>성능 최적화</b>",
        ],
      },
      {
        name: "컨텐츠 열람 및 다운로드 현황 보고 자료 작성 자동화 구축",
        date: "2024.05",
        descriptions: [
          "웹 뷰어를 통한 컨텐츠 열람 및 파일 다운로드 현황 자료 작성 자동화를 구축하여 <b>자료 작성 시간 평균 15분에서 2분으로 감소</b>",
          "일자별 열람 현황 및 파일 다운로드 성공, 실패 및 중단 현황에 대한 내용이 담긴 Excel 자료 작성 자동화 구축",
          "구축한 자동화 시스템을 팀에 배포하여 <b>팀 업무 효율성 향상에 기여</b>",
        ],
      },
      {
        name: "클라우드 플랫폼 컨텐츠 열람 상세 현황 조회 기능 개발",
        date: "2024.05 ~ 2024.06",
        descriptions: [
          "뷰어 이용 계약을 맺은 기업 측에서, <b>컨텐츠 열람 횟수의 변동 현황을 한눈에 확인하고 싶다는 내용의 피드백</b>을 전달받은 상황",
          "설정한 기간 범위 내 총 열람 횟수만 나타내는 기존 방식에 더해, <b>기간 범위 내 일자별 각각의 열람 횟수를 상세 조회할 수 있는 기능</b>에 대한 아이디어 제시 및 개발",
          "해당 기능 개발을 통해 고객사에 <b>데이터 기반 인사이트 제공</b>에 기여",
        ],
      },
      {
        name: "클라우드 사용자 인증 방식 변경 및 API 문서화",
        date: "2024.03 ~ 2024.06",
        descriptions: [
          "클라우드 플랫폼의 인증 방식을 세션에서 토큰 방식으로 변경하여 <b>서버 부하 개선</b>",
          "총 두 개의 클라우드 플랫폼의 서버는 <b>중복된 비즈니스 로직이 많지만 분리되어 운용</b>되던 상황",
          "따라서 <b>두 서버 통합</b> 진행 및 토큰을 통해 플랫폼 분기 처리하여 <b>클라우드(EC2) 리소스 효율 개선</b>",
          "로우 쿼리로 작성된 서버 측 코드를 TypeORM의 엔티티에 기반한 ORM 방식으로 변경하여 <b>유지보수성 및 코드 가독성 증진</b>",
          "약 100개 가량의 API에 대한 통합 API 문서 작성 및 팀 배포를 통해 <b>팀 개발 생산성 향상</b>",
        ],
      },
      {
        name: "뷰어 내 재생 컨텐츠 원본 파일 다운로드 기능 개발",
        date: "2024.02",
        descriptions: [
          "데이터를 Chunk로 나눠 비동기 처리하는 Stream 방식으로 다운로드 기능을 구현하여 <b>대용량 파일 다운로드의 안정성 향상</b>",
          "학회 행사 당일 <b>약 4,000명의 사용자의 기능 사용에도 안정적인 대응</b>",
          "Stream 객체의 이벤트를 활용해 다운로드 성공, 실패 및 중단 원인을 기록하여, 추후 <b>신속한 이슈 대응이 가능</b>하도록 구현",
          "안드로이드 디바이스 내 <b>카카오톡 인앱 브라우저에서 다운로드가 시작되지 않는 이슈</b> 발생",
          "안드로이드 및 카카오톡 인앱 브라우저일 경우, User agent를 통해 해당 출처를 분기 처리하여 <b>외부 브라우저 앱으로 리다이렉트시키는 방식으로 해결</b>",
          "외부에서 다운로드 API를 호출할 수 없도록 <b>토큰 인증을 활용한 보안 적용</b>",
        ],
      },
      {
        name: "도서 컨텐츠 웹 뷰어 성능 최적화",
        date: "2023.12 ~ 2024.01",
        descriptions: [
          "뷰어 내 컨텐츠에 대한 텍스트 선택 시 글자별로 div 태그가 생성되어, DOM 과다 생성으로 브라우저 메모리 부하 우려",
          "해당 사항에 대한 개선을 위해 텍스트 선택 시 height 값을 기준으로 텍스트를 병합하여 셀렉션 태그를 통합, <b>DOM 요소 수를 평균 4~5개에서 1개로 줄여 브라우저 리소스 효율 개선</b>",
          "뷰어의 목차 리스트에 Intersection observer를 활용하여 현재 뷰포트 내에 존재하는 컨텐츠만 조건부 API 호출 및 렌더링하도록 변경하여 <b>초기 로드 성능 개선 (Lighthouse 점수 68점 -> 75점 향상)</b>",
          "이후, 이미 응답받은 목차 데이터는 Map에 저장하도록 하여 중복 API 호출 방지를 통한 <b>서버 부하 개선</b>",
          "Fetch abort controller를 활용하여 목차 데이터에 대해 사용자 의도와 무관한 동작에 대해 <b>API 호출 방지</b> 적용",
        ],
      },
    ],
  },
  {
    image: WJTBLogo,
    title: "웅진씽크빅",
    date: "2023.02 ~ 2023.07",
    descriptions: [
      "유 초등 및 중등 학습 자료를 출판하고, 전자 교육 자료를 제작 및 서비스하는 교육 플랫폼입니다.",
      "기존 안드로이드 기반 컨텐츠 뷰어 대비 <b>성능 및 사용성이 개선</b>되고, <b>다양한 디바이스 해상도를 고려하여 반응형을 적용한 React 기반 컨텐츠 웹 통합 뷰어를 개발</b>하였으며, <b>중학 교육 플랫폼 기능 개발 및 유지보수</b>를 담당하였습니다. ",
    ],
    contributionList: [
      {
        name: "코드 리뷰 도입 및 배포 자동화 파이프라인 구축",
        date: "2023.06",
        descriptions: [
          "중학 교육 플랫폼 개발 팀에 <b>코드 리뷰 프로세스를 도입하고 배포 자동화 파이프라인 구축</b>",
          "코드리뷰 도입 및 배포 자동화 구축을 위해 PR 기능과 CI/CD 적용이 가능한 새로운 형상관리 저장소로 프로젝트 이전",
          "코드 리뷰 프로세스를 도입하여 <b>팀원 간 소통 기회 확대 및 업무 생산성 증진에 기여</b>",
          "GitLab Runner를 활용하여 프로젝트 빌드 및 FTP를 통한 빌드 파일 업로드 작업을 자동화하여 <b>배포 자동화 구축 팀 개발 생산성 향상에 기여</b>",
        ],
      },
      {
        name: "프로젝트 레거시 코드 개선",
        date: "2023.06",
        descriptions: [
          "React 기반의 중학 교육 플랫폼 프로젝트에 대해 컴포넌트 공통화 및 중복 라이브러리 정리를 통한 프로젝트 <b>리팩토링 진행</b>",
          "동일한 기능을 수행하지만 다른 종류의 중복 라이브러리 정리 <b>(의존성 리스트 100개 => 30개로 감소)</b>",
          "페이지별 중복 작성된 컴포넌트를 공통화하여 <b>재사용성 증진</b>",
          "리팩토링을 통해 <b>번들 사이즈 및 빌드 성능 향상, 팀 개발 생산성 향상에 기여</b>",
        ],
      },
      {
        name: "홈 배너 렌더링 테스트 환경 개선",
        date: "2023.06",
        descriptions: [
          "사용자에게 일자에 따른 서비스 관련 정보를 제공하기 위한 배너에 대해 <b>렌더링 테스트 환경 개선</b>",
          "기존 배너 이미지에 대한 API는 <b>오늘 날짜에 해당하는 배너 이미지만 받아올 수 있는 구조</b>였기 떄문에, 신규 배너가 <b>일정에 맞게 정상적으로 렌더링되는지 테스트가 불가능</b>했던 문제 존재",
          "따라서 API 내 <b>쿼리 파라미터로 테스트해야할 일자를 추가하는 것을 백엔드 팀에 제안 및 적용</b>, 배너가 일정 별로 <b>정상적으로 렌더링되는지에 대한 테스트 환경 구축</b>",
          "이후 배너 변경 당일 정상적으로 렌더링되는지 대한 테스트가 가능해져 <b>서비스 안정성 향상에 기여</b>",
        ],
      },
      {
        name: "교육 컨텐츠 웹 통합 뷰어 개발",
        date: "2023.02 ~ 2023.05",
        descriptions: [
          "기존에 서비스되던 네이티브 컨텐츠 뷰어를 사용해보고 아쉬움이 있어, <b>성능 및 사용성과 디바이스 접근성을 개선</b>한 교육 컨텐츠 웹 뷰어 개발",
          "<b>다양한 유형의 컨텐츠(PDF, 오디오북, EPUB, M3U8)를 웹 환경에서 재생하는 환경을 구축</b>하는 매커니즘 이해",
          "오디오 북 뷰어를 구현하며 구간별 음원 재생 정보를 담은 마크업 언어(XML)에 대한 이해 증진",
          "Flutter 환경에서의 웹 뷰어 재생 구현을 통해 Webview_flutter 패키지를 활용한 <b>Webview 매커니즘 이해</b>",
          "서비스중인 컨텐츠가 뷰어 내에서만 재생되도록 하는 DRM 복호화(Decrypt)를 구현하며 <b>Web crypto에 기반한 암호화 & 복호화 기법 이해</b>",
          "컨텐츠 복호화 과정에 대칭키 알고리즘인 AES, 연산 스레드인 Web worker를 도입하여, <b>컨텐츠 초기 로드 성능 개선 (20s => 10s)</b>",
          "<b>대용량 파일에 대한 처리 중 브라우저 성능 이슈가 발생</b>하여 데이터를 Chunk 단위로 비동기로 처리하여 해결하기 위해 <b>Readable stream을 도입하여 해결</b> <b>(컨텐츠 열람 속도 6s => 2s로 단축)</b>",
          "도서 뷰어 페이지 로드 방식을 현재 페이지 외 렌더링 될 페이지들을 미리 로드하는 방식으로 변경하여 <b>페이지 넘김 시 사용성 개선</b>",
          "Canvas 태그와 좌표를 이용한 Clip으로 선택 영역 캡쳐 기능을 구현하여 <b>정밀한 캡쳐 영역 제어 제공</b>",
          "영상 뷰어 내 스트리밍 파일을 위한 HLS를 내장하여 <b>CMS 비용 절감 기대</b>",
        ],
      },
      {
        name: "중학 교육 플랫폼 화상 과외 일정 조회 기능 확장",
        date: "2023.06",
        descriptions: [
          "기존에는 <b>단일 과목에 대한 과외 일정만 조회가 가능</b>하였기 때문에, <b>여러 과목에 대한 일정 조회가 불가능</b>하던 상황",
          "해당 문제를 해결하기 위해 <b>일정을 과목별로 세분화</b>하는 작업 진행",
          "과목별 세분화 작업을 위해, <b>백엔드 팀과의 협업을 거쳐 API 문서 추가 작성 및 기능 구현</b>",
          "과외 일정 캘린더 내 색상을 통해 과목이 구분되도록 UI 구현",
        ],
      },
    ],
  },
];
