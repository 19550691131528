import styled from "styled-components";

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 3rem;

  gap: 4rem;
`;

export default { ContentsContainer };
